import util from "lib/util"
import { mapState } from 'vuex'
export default {
  name: "dispatch",
  data(){
    return {
      position: {
        lat: util.query("lat"),
        lng: util.query("lng")
      },
      picker: null,
      marketIndex: 0,
      remarks: [],
      orderDetail: {},
      tradeTime: {
        show: false,
        tips: ''
      },
      more: {
        show: false,
        open: false
      },
      iswx: this.$store.state.globalData.isWechat,
      showWxModal: false,
      showDispatch: false,
      islogin: false,
      showLogin: false,
      auth: this.$store.state.userAuth,
      showCheckOffset: false,
      showAuto:false,
      doAuth: false,
      sendAgain: util.getSession("reorder") === 'do',
      loginPhone: '',
      loading: {
        text: '奋力加载中...',
        show: true
      },
      waitSubmit: false,
      order: {
        // @mock
        rec: {
          xzqName: '',
          address: '',
          name: '',
          phone: '',
          fixedPhone: ""
        },
        send: {
          xzqName: '',
          address: '',
          name: '',
          phone: '',
          fixedPhone: ""
        },
        valins: {
          agree: false,
          value: '',
          fee: 0
        },
        company: 'default',
        market: {},
        weight: 1,
        payment: "SHIPPER",
        comment: "",
        cargo: {},
        gotaddr: {},
        otherCargo: '',
        cargoInclude: [],
        coupon: {},
        maxCoupon: 0, //最大优惠券的值
        companys: {},
        companyText: '',
        ordering: false,
        agree: util.getStorage("dispatchAgree") || false,
        sign: ""
      },
      days: {
        today: "今天",
        tomorrow: "明天",
        aftertomorrow: "后天"
      },
      times: {},
      autos: [],
      selectDaytime: {
        day: '',
        time: '',
        timeKey: ''
      },
      payways: {
        SHIPPER: "寄付",
        CONSIGNEE: "到付"
      },
      priceinfo: {},
      contactList: [],
      contactStatus: { //用于记录通讯录相关状态
        filter: 3, //通信录列表展示的类型，默认展示全部，即值为3
        type: '', //当前激活的通信录类型（寄件人|收件人）
        index: -1, //当前正在编辑的通信录索引
        rec: -1, //当前选中的收件人索引
        send: -1, //当前选中的寄件人索引
        keyword: '', //当前搜索的通信录关键词
        detail: { //当前详情页正在展示的通信录详情，这有助于避免在用户返回从而撤销编辑时对原有地址的影响
          reconize: '',
          mobile: '',
          name: '',
          xzq: '',
          address: ''
        }
      },
      commentExm: [{
        lable: '按寄件地址来取件'
      }, {
        lable: '到了打电话'
      }, {
        lable: '不方便接电话'
      }, {
        lable: '缺少包装'
      }, {
        lable: '上午来取件'
      }, {
        lable: '下午来取件'
      }],
      validator: {
        phone: "",
        code: "",
        time: 0,
        random: Math.random(),
        img: "",
        valid: true,
        validating: false,
        sended: false
      },
      comlist: [],
      expressBrands: [],
      cargos: [],
      coupons: [],
      priceTime: null,
      sntInfo: {},
      kdNum: util.query('kdNum') || '',
      sntSign: util.query("sntSign") || '',
      backExpId: util.query("backExpId") || "",
      orderSource: util.query("orderSource") || "",
      showContraband: false,
      showProtocol: false,
      showComment: false,
      showCoupon: false,
      showComlist: false,
      showValins: false,
      showCargo: false,
      showTime: false,
      showPayment: false,
      showPriceinfo: false,
      showPrepay: false
    }
  },
  methods: {
    init() {
      this.login(() => {
        this.islogin = true
        this.initData()
      }, () => {
        this.initData()
      })
    },
    initData: function() {
      this.getSntInfo()
      this.getExpressBrand(true)
      this.getRemarks()
      this.getGoods()
      this.contactInit()
      if(this.islogin) {
        //!this.auth && this.getAuth()
        this.getUserInfo()
      }
    },
    showNotice: function () { // 显示notice
      this.isShowNotice = true;
    },
    goCourier: function() {
      this.navigator.switch({
        name: "courier"
      })
    },
    getSntInfo: function() {
      if(!this.sntSign) return
      let data = this.sntInfo = util.getSession("sendbackMerchant") || {}
      let sender = (util.getSession("sendbackForm") || {send: {}}).send
      util.setSession("sendbackMerchant", "")
      util.setSession("sendbackForm", "")
      this.orderSource = "snt_" + this.orderSource
      if(data.recName) {
        this.selectContact({
          name: data.recName,
          phone: data.recMobile || "",
          fixedPhone: data.recTel || "",
          address: data.recAddr,
          xzqName: [data.recProvince, data.recCity, data.recDistrict].join(","),
        },  'rec')
      }
      this.selectContact({
        name: sender.name,
        phone: sender.type == 'mobile' ? sender.mobile : "",
        fixedPhone: sender.type != 'mobile' ? sender.fixedPhone : "",
        address: sender.address,
        xzqName: sender.xzqArr.join(",")
      }, 'send')
    },
    getExpressBrand: function(load) {
      var order = this.order;
      var market = this.order.market
      this.order.market = {}
      this.$http.get('/apicenter/kdmkt.do?method=expressBrand', {
        data: {
          latitude: this.position.lat,
          longitude: this.position.lng,
          sentxzq: order.send.xzqName,
          sendAddr: order.send.address,
          recName: order.rec.name,
          recAddr: order.rec.address,
          recxzq: order.rec.xzqName,
          doortime: this.selectDaytime.day ? this.days[this.selectDaytime.day] + this.selectDaytime.time : '',
          cargo: order.cargo.type == 'other' ? order.otherCargo : order.cargo.name,
          weight: order.weight,
          apiversion: 14,
          kdNum: this.kdNum,
          sntSign: this.sntSign || "",
          backExpId: this.backExpId || ""
        },
      }).then(result => {
        if (result.data && result.data.length) {
          this.expressBrands = result.data
          if(order.send.xzqName && order.rec.xzqName && this.cargoStr){
            for(let i = 0; i < result.data.length; i++) {
              if(result.data[i].com === market.com) {
                this.selectMarket(result.data[i])
                break
              }
            }
            if(!this.order.market.com) this.selectMarket(result.data[0])
          }
          if(load) {
            this.showDispatch = true
          }
        } else {
          this.$toast("没有符合条件的快递公司")
        }
        this.loading.show = false
      }).catch(r => {
        this.$toast(r.message)
        setTimeout(() => {
          this.goCourier()
        }, 1000)
        this.loading.show = false
      })
    },
    selectMarket: function(item) {
      this.order.market = item
      if(item.com === "kuaidi100") {
        this.getCompany()
        this.getCoupons()
      }
      this.getDoortime()
      this.getPrice()
      this.calcTradeTime()
    },
    setOtherGoods: function() {
      this.cargos = [{
        name: "其他",
        type: "other"
      }]
      this.order.cargo = {
        name: "其他",
        type: 'other'
      }
    },
    getGoods: function() {
      let data = {}
      if(this.sntSign) {
        data.templateType = this.sntInfo.tempType || ""
      }
      this.$http.get("/mobclient/apigateway.do?method=goodsList", {
        data: data
      }).then( (result) =>  {
        if(result.data.length) {
          this.cargos = result.data
        } else {
          this.setOtherGoods()
        }  
      }).catch(() => {
        this.setOtherGoods()
      })
    },
    getRemarks: function() {
      this.$http.get("/mobclient/apigateway.do?method=courierLeaMsgList", {
        data: {
          coname: this.$store.state.globalData.coname || 'kuaidi100'
        }
      }).then(r => {
        if (r.data && r.data.length) {
          this.remarks = r.data
          for (let i = 0; i < r.data.length; i++) {
            r.data[i].isSelected && this.catComment(r.data[i].lable)
          }
        } else {
          this.remarks = this.commentExm
        }
      }).catch(() => {
        this.remarks = this.commentExm
      })
    },
    calcTradeTime: function() {
      var now = new Date()
      var hour = now.getHours()
      var minute = now.getMinutes()
      var market = this.order.market
      var timeList = market.serviceTime ? market.serviceTime.split('-') : ''
      var name = this.order.market.com === "kuaidi100" ? "" : this.order.market.name
      if (timeList && timeList[1]) {
        var begin = timeList[0].split(':'),
          end = timeList[1].split(':');
        var beginHour = parseInt(begin[0]),
          beginMinute = parseInt(begin[1]);
        var endHour = parseInt(end[0]), endMinute = parseInt(end[1]);
        this.serviceTime = [('0' + timeList[0]).slice(-5), ('0' + timeList[1]).slice(-5)]
        if (hour > endHour || hour == endHour && minute > endMinute) { //下班时间
          this.tradeTime.show = true;
          this.tradeTime.tips = name + '营业时间为<strong>' + market.serviceTime + '</strong>，现在下单预计明天的<strong>' + timeList[0] + '</strong>后才能上门取件！'
        } else if (hour < beginHour || hour == beginHour && minute < beginMinute) { //上班前
          this.tradeTime.show = true;
          this.tradeTime.tips = name + '营业时间为<strong>' + market.serviceTime + '</strong>，现在下单预计<strong>' + timeList[0] + '</strong>后才能上门取件！'
        } else if (endHour * 60 + endMinute - (hour * 60 + minute) < 30) { //下班前半小时
          this.tradeTime.tips = name + '<strong>' + timeList[1] + '</strong>下班，可能无法及时上门服务，如有急件，请先联系确认后再下单'
          this.tradeTime.showPhone = true
          this.tradeTime.show = true
        } else {
          this.tradeTime.tips = ''
          this.tradeTime.show = false
        }
      } else {
        this.tradeTime.tips = ''
        this.tradeTime.show = false
        this.serviceTime = ["08:00", "20:00"]
      }
    },
    toCompany: function() {
      var order = this.order
      if(!order.send.xzqName || !order.rec.xzqName) return this.$toast("请先填写收寄件人信息")
      if(!this.cargoStr) return this.$toast('请先填写物品信息')
    },
    getCompany: function() {
      var order = this.order
      this.comlist = []
      this.order.companys = []
      if(!this.order.market.dispatchid) return 
      if (order.rec.xzqName && order.send.xzqName && order.cargo.name) {
        this.$http.get("/apicenter/order.do?method=availableCom4dispatch", {
          data: {
            dispatchid: this.order.market.dispatchid,
            recxzq: order.rec.xzqName,
            sendxzq: order.send.xzqName,
            cargo: order.cargo.type == 'other' ? order.otherCargo : order.cargo.name,
            cargodesc: order.cargoInclude.join(","),
            weight: order.weight,
            sendAddr: order.send.address,
            recAddr: order.rec.address,
            mktids: this.order.market.dispatchInfo.mktids.join(","),
            apiversion: 14
          }
        }).then(result => {
          this.comlist = result.data
        })
      }
    },
    selectCompany: function(com) {
      var index = this.order.companys[com.kuaidiCom],
        coms = this.order.companys
      if (com.useable == 'N') {
        return this.$toast(com.unablemsg, 1000)
      }
      typeof coms.length === 'undefined' && (coms.length = 0)
      if (index) {
        this.$delete(this.order.companys, com.kuaidiCom)
        coms.length--
      } else {
        this.$set(this.order.companys, com.kuaidiCom, com)
        coms.length++
      }
      if (coms.length) {
        this.order.company = ''
      } else {
        this.order.company = 'default'
      }
    },
    getPrice: function() { //获取邮费
      var order = this.order;
      if (!order.send.xzqName || !order.rec.xzqName || !this.cargoStr) return
      order.coupon = {}
      this.priceTimer && clearTimeout(this.priceTimer)
      this.priceTimer = setTimeout(() => {
        this.priceinfo = {};
        this.$http.get("/apicenter/order.do?method=pfprice", {
          data: {
            sentxzq: order.send.xzqName,
            recxzq: order.rec.xzqName,
            sentAddr: order.send.address,
            recAddr: order.rec.address,
            weight: order.weight,
            comlist: order.comable,
            doortime: this.selelcttime,
            dispatchid: order.market.dispatchid || "",
            sign: order.market.sign || "",
            cargo: order.cargo.type == 'other' ? order.cargo.otherCaogo : order.cargo.name || '',
            apiversion: 14
          },
          handleFail: false
        }).then(result => {
          var priceinfo = this.priceinfo = result.data
          for (var i = 0; i < this.coupons.length; i++) {
            if (this.coupons[i].id == priceinfo.couponId) {
              this.order.coupon = this.coupons[i]
              this.order.maxCoupon = this.coupons[i].top_limit
              break
            }
          }
        })
      })

    },
    contactInit: function() { //获取寄件地址并设置上次地址
      var address = util.getSession("reOrderAddress")
      if (address && this.sendAgain) {
        address.rec && this.selectContact(address.rec, 'rec')
        address.send && this.selectContact(address.send, 'send')
      }
    },
    selectContact: function(data, type) {
      if (!data) return
      if (/台湾|香港|澳门/.test(data.xzqName) && type == 'send') {
        this.$confirm({
          content: "抱歉，目前暂不支持港澳台和国际件。服务正在筹备中，敬请期待！",
          type: 'alert',
          confirmText: "我知道了"
        })
        return
      }
      if(data.address.length < 4) {
        return this.$toast("详细地址不能少于4个字")
      }
      this.lastaddress = undefined
      this.order[type] = data
      this.getPrice()
    },
    showContact: function(type) {
      if(this.sntSign) return
      if (!this.islogin) {
        return this.navigator.push({
          name: "login"
        })
      }
      this.broadcast() // 接收地址填充的通知
      this.navigator.push({
        name: "addressList",
        params: {
          source: "dispatch"
        },
        query: {
          from: "dispatch",
          tag: type,
          selectId: this.order[type].id || -1
        }
      })
    },
    broadcast() {
      this.$broadcastChannel.$on("fillContact", (addr, type) => {
        this.selectContact(addr, type)
        this.$broadcastChannel.$off("fillContact")
      })
    },
    showSelectContact: function(type) {
      if(this.sntSign) return
      if (!this.islogin) {
        return this.navigator.push({
          name: "login"
        })
      }
      this.broadcast()
      this.navigator.push({
        name: "addressDetail",
        params: {
          detail: Object.assign({}, this.order[type]),
          source: "dispatch",
          tag: type
        }
      })
    },
    setActionCache(type) {
      let cache = this.cache = this.cache || {}
      let order = this.order
      switch (type) {
        case 'showCargo':
          cache.cargo =  Object.assign({}, order.cargo)
          cache.otherCargo = order.otherCargo
          cache.cargoInclude = order.cargoInclude.slice(0)
          break
        case 'showValins': 
          cache.valins = Object.assign({}, order.valins)
          break
        case 'showComment':
          cache.comment = order.comment
          break
        case 'showTime':
          cache.selectDaytime = Object.assign({}, this.selectDaytime)
          break
        case 'showComlist':
          cache.companys = Object.assign({}, order.companys)
          cache.company = order.company
          break
      }
    },
    resetAction(type) {
      let cache = this.cache = this.cache || {}
      let order = this.order
      switch (type) {
        case 'cargo':
          order.cargo = cache.cargo
          order.otherCaogo =  cache.otherCargo
          order.cargoInclude = cache.cargoInclude
          break
        case 'valins': 
          order.valins = cache.valins
          break
        case 'comment':
          order.comment = cache.comment
          break
        case 'time':
          this.selectDaytime = cache.selectDaytime
          break
        case 'comlist':
          order.companys = cache.companys
          order.company = cache.company
          break
      }
    },
    showAction: function(type) {
      if (type == 'showComlist' || type == 'showTime' || type === 'showCargo') {
        if (!this.order.rec.xzqName || !this.order.send.xzqName) {
          return this.$toast("请先填写收寄件人信息", 1000)
        } else if (type != 'showCargo' && (this.order.cargo.type == "other" && !this.order.otherCargo || !this.order.cargo.name)) {
          return this.$toast("请先填写物品信息", 1000);
        }
      } else if(type === 'showValins' && this.showValinsForm) {
        return this.$toast("请先填写收寄件人和物品信息")
      } else if(type === 'showPayment' && !this.sntInfo.collection) {
        return this.$toast("仅支持寄付方式")
      }
      this.setActionCache(type)
      if (type == 'showCoupon' && !this.coupons.length) return
      type === 'showTime' && this.calltime()
      this[type] = true
    },
    actionConfirm: function(type, $event) {
      if (type == 'cargo') {
        if (this.order.cargo.type == "other" && !this.order.otherCargo || !this.order.cargo.name) return this.$toast("请输入物品名称", 1000)
        if(!/\d+/.test(this.order.weight)) return this.$toast("请输入正确的预估重量")
      }  else if(type === 'valins' && this.order.valins.value) {
        if(this.order.valins.value % 100) return this.$toast("请输入100的整倍数")
        if(!this.order.valins.agree) return this.$toast("请阅读并同意《快递100平台保价增值服务协议》")
      }
      if (type == 'prepay') {
        return this.navigator.push({
          name: "dispatchPrepay",
          query: {
            expid: this.orderDetail.expId,
            dispatchid: this.order.market.dispatchid
          }
        })
      }
      if (type == 'cargo') {
        this.getCompany()
        this.getPrice()
        this.getExpressBrand()
      }
      $event.hide()
    },
    getDoortime: function() {
      var order = this.order
      if(!order.send.xzqName || !order.rec.xzqName || !order.market.sign && !order.market.dispatchid) return 
      this.times = []
      this.selectDaytime.day = ""
      this.selectDaytime.time = ""
      this.$http.get("/apicenter/kdmkt.do?method=queryDoorTimeList", {
        data: {
          type: "MKTSENT",
          dispatchid: this.order.market.dispatchid || "",
          sign: this.order.market.sign,
          comable: order.comable == 'default' ? '' : order.comable,
          sendAddr: order.send.address,
          sendxzq: order.send.xzqName,
          recAddr: order.rec.address,
          recxzq: order.rec.xzqName,
          gotaddr: order.gotaddr.addr,
          longitude: this.position.lng,
          latitude: this.position.lat,
          mktids: order.market.dispatchid ? order.market.dispatchInfo.mktids.join(",") : "",
          apiversion: 14
        }
      }).then(result => {
        this.times = result.data
        this.sntSign && this.calltime()
      }).catch(() =>  {
        this.$toast("上门时间获取失败，请稍后再试")
      })
    },
    calltime: function() {
      if(this.times.today && this.times.today.length) {
        this.$set(this.days, 'today', '今天')
        if (!this.selectDaytime.day) this.selectDaytime.day = 'today'
      } else {
        this.$delete(this.days, 'today')
        if (!this.selectDaytime.day) this.selectDaytime.day = 'tomorrow'
      }
    },
    selectDoortime: function(item) {
      if (item.itemName == 2 && !this.times.isExistMkt) return
      this.selectDaytime.time = item.itemValue
      this.selectDaytime.timeKey = item.itemName
      this.getPrice()
    },
    togglePriceInfo: function() {
      this.showPriceinfo = !this.showPriceinfo
    },
    validateOrder: function() {
      var word, returnValue = false
      var orderInfo = this.order
      var market = this.order.market
      if (!orderInfo.send.name) {
        this.$toast("请先填写寄件人", 1000)
      } else if (!orderInfo.rec.name) {
        this.$toast("请先填写收件人")
      } else if (orderInfo.send.name.length <= 1 && market.type == 'MARKET') {
        this.$toast("根据国家有关政策法规，请填写寄件人真实姓名（姓名不能为一个字），如有疑问请联系客服：400-000-0387")
      } else if (orderInfo.send.name.length > 5 && market.type == 'MARKET') {
        this.$toast("根据国家有关政策法规，请填写寄件人真实姓名（姓名不能超过5个字），如有疑问请联系客服：400-000-0387")
      } else if (word = orderInfo.send.name.match(/先生|小姐|女士|总|经理/) && market.type == 'MARKET') {//eslint-disable-line
        this.$toast("根据国家有关政策法规，请填写寄件人真实姓名（姓名不能包含“" + word[0] + "”字眼），如有疑问请联系客服：400-000-0387")
      } else if (orderInfo.send.name.match(/\w/) && market.type == 'MARKET') {
        this.$toast("根据国家有关政策法规，请填写寄件人真实姓名（请勿使用非中文字符），如有疑问请联系客服：400-000-0387");
      } else if (orderInfo.send.name == orderInfo.rec.name && orderInfo.send.phone == orderInfo.rec.phone && orderInfo.send.address == orderInfo.rec.address) {
        this.$toast("收寄件人不能相同")
      } else if (!orderInfo.cargo.name) {
        this.$toast("请填写物品信息", 1000)
      } else if (!this.selectDaytime.day) {
        this.$toast("请选择期望上门时间", 1000)
      } else if (this.sntSign){
        if (!this.islogin) {
          if(!this.validator.phone || !/1\d{10}/.test(this.validator.phone)) {
            this.$toast("请输入正确的验证手机号")
            return false
          } else if(!this.validator.code) {
            this.$toast("请输入手机验证码")
            return false
          } else {
            this.$toast("请先验证手机号")
            return false
          }
        }
        returnValue = true
      } else {
        returnValue = true
      }
      return returnValue
    },
    checkOrder: function() {
      var order = this.order
      if (this.order.ordering) return
      if (!this.validateOrder()) return
      if (!this.ignoreMobileCheck && !this.checkMobile()) return
      if (!this.sntSign && (!order.send.phone && order.send.fixedPhone || order.send.phone && !/^1\d{10}$/.test(order.send.phone))) {
        this.$confirm({
          content: "抱歉，暂时不支持座机为寄件人联系方式，请填写正确的手机号码",
          type: "alert",
          confirmText: "我知道了"
        })
        return false
      }
      // 实名认证后置到订单详情
      // if (!this.auth && !this.login2submit) {
      //   this.doAuth = true
      //   this.$confirm({
      //     content: "依据国家邮政管理局要求，寄件需要实名登记。登记后，再次寄件无需重复登记。",
      //     confirmText: "去认证",
      //     cancelText: this.isShenzhen ? "取消" : "跳过",
      //   }, () => {
      //     this.waitSubmit = true
      //     this.goAuth()
      //   }, () => {
      //     if (this.isShenzhen) {
      //       this.doAuth = false
      //       this.waitSubmit = false
      //     } else {
      //       this.login2submit = true
      //       if (this.islogin) {
      //         this.submit()
      //       } else {
      //         this.navigator.push({
      //           name: "login"
      //         })
      //         this.waitSubmit = true
      //       }
      //     }
      //   })
      //   return false
      // }
      if (!this.order.agree) {
        return this.$toast('请阅读并同意寄件服务协议')
      }
      return true
    },
    checkOffset: function() {
      var order = this.order
      if(!this.order.market.dispatchid) {
        return this.doSubmit()
      }
      let loading = this.$loading("正在提交，请稍后...")
      this.$http.post("/apicenter/order.do?method=checklocaoff", {
        data: {
          dispatchid: order.market.dispatchid,
          longitude: this.position.lng,
          latitude: this.position.lat,
          sendxzq: order.send.xzqName,
          sendAddr: order.send.address,
          gotaddr: order.gotaddr.addr,
          gotxzq: order.gotaddr.xzq
        }
      }).then(result => {
        loading.hide()
        if (result.data.offposition <= 1000 || result.data.samearea) {
          this.doSubmit()
        } else {
          this.showCheckOffset = true
          this.offsetInfo = result.data
        }
      }).catch(() => {
        loading.hide()
        this.doSubmit()
      })
    },
    checkMobile: function() {
      var count = 0;
      if (this.userMobile && this.order.send.phone) {
        for (var i = 0; i < this.userMobile.length; i++) {
          if (this.userMobile[i] == this.order.send.phone[i]) count++;
        }
        if (count > 8 && count < 11) {
          this.$confirm({
            content: "当前绑定手机号为" + this.userMobile + "，您的寄件人联系方式可能填写错误",
            confirmText: "继续下单",
            cancelText: "修改寄件人信息",
            reverse: true
          }, () => {
            this.ignoreMobileCheck = true
            this.submit()
          }, () => {
            // @Mock
            this.showContactDetail()
          })
          return false
        } else {
          return true
        }
      } else {
        return true
      }
    },
    selectgotAddr: function(val) {
      var info = val ? this.offsetInfo.gotdispatchinfo : this.offsetInfo.senddispatchinfo
      if (!info || !info.id) {
        this.$toast("该地址附近没有优选取件服务")
      } else {
        this.order.market.dispatchid = info.id
        this.order.market.dispatchInfo.id = info.id
        this.order.market.dispatchInfo.mktids = info.mktids
        this.doSubmit(val)
      }
      this.showCheckOffset = false
    },
    doSubmit: function(val) {
      var order = this.order
      var form = []
      if(!this.expressBrands.length || !this.order.market.com) return this.$toast("附近没有符合条件的快递公司")
      let loading = this.$loading("正在提交，请稍后...")
      var submitData = {
          type: "MKTSENT",
          dispatchid: order.market.dispatchid || "",
          sign: order.market.sign || "",
          picurl: '', //图片
          doortime: this.selectDaytime.day ? this.days[this.selectDaytime.day] + this.selectDaytime.time : '',
          comable: order.comable == 'default' ? '' : order.comable,
          sendName: order.send.name,
          sendMobile: order.send.phone || order.send.fixedPhone,
          sendAddr: order.send.address,
          sendxzq: order.send.xzqName,
          recName: order.rec.name,
          recMobile: order.rec.phone || order.rec.fixedPhone,
          recAddr: order.rec.address,
          recxzq: order.rec.xzqName,
          raddrid: order.rec.id,
          saddrid: order.send.id,
          gotaddr: val === 1 ? order.gotaddr.addr : order.send.address,
          cargo: order.cargo.type == 'other' ? order.otherCargo : order.cargo.name,
          cargodesc: order.cargoInclude.join(","),
          valinspay: order.market.dispatchid ? order.valins.value || "" : "",
          payment: order.payment || "SHIPPER",
          weight: order.weight,
          comment: order.comment,
          couponid: order.market.dispatchid ? order.coupon.id || '' : "",
          priceTimeInfo: this.priceinfo.firstWeightPrice ? "首重" + this.priceinfo.firstWeightPrice + "元，续重" + this.priceinfo.overWeightPrice + "元/公斤" : '',
          longitude: order.send.longitude || this.position.lng,
          latitude: order.send.latitude || this.position.lat,
          mktids: order.market.dispatchid ? order.market.dispatchInfo.mktids.join(",") : "",
          orderSource: this.submitOrderSource,
          stampid: util.query('stampid') || '',
          siid: util.query("siid") || '',
          linkSource: util.query("linkSource") || '',
          optor: util.query("optor") || '',
          ref: document.referrer,
          ua: navigator.userAgent,
          /* */
          payway: '',
          apiversion: 18
        },
        url = '/apicenter/order.do?method=submitOrder'
      if(this.sntSign) {
        submitData.isBackOrder = "Y"
        submitData.sntSign = this.sntInfo.sntSign
        submitData.backExpId = this.backExpId
        submitData.kdNum = this.kdNum
        for(var i = 0; i < this.sntInfo.fieldList.length; i++) {
          form.push({
            id: this.sntInfo.fieldList[i].id,
            val: this.sntInfo.fieldList[i].value
          })
        }
        submitData.customizeVal = JSON.stringify(form)
        if(!this.islogin) {
          submitData.mobile = this.validator.phone
          submitData.smscode = this.validator.code
        }
      }
      this.$http.get(url, {
        data: submitData
      }).then((result) => {
        loading.hide()
        this.$toast('下单成功', 2000);
        if(result.data.token){
          this.setToken(result.data.token)
          this.login()
        }
        let jump = this.sntSign ? 'replace' : 'push'
        if(order.market.dispatchid) {
          util.setSession("reorder", '')
          this.orderDetail = result.data
          this.navigator[jump]({
            name: "dispatchDetailIndex",
            query: {
              expid: this.orderDetail[0].expId,
              dispatchid: order.market.dispatchid
            }
          })
          /* this.showAction('showPrepay')
          this.tprepay = setInterval(() => {
            if (this.orderDetail.premanenttime) {
              this.orderDetail.premanenttime--
            } else {
              this.tprepay && clearInterval(this.tprepay)
              this.navigator[jump]({
                name: "dispatchDetail",
                query: {
                  expid: this.orderDetail[0].expId,
                  dispatchid: order.market.dispatchid
                }
              })
            }
          }, 1000) */
        } else {
          this.navigator[jump]({
            // name: "normalDetail",
            name: "dispatchOfficial",
            query: {
              expid:  result.data[0].expId,
              sign: order.market.sign
            }
          })
        }
        this.order.ordering = false
        this.loading.show = false
      }).catch(result => {
        if (result.status == 506) {
          this.$confirm({
            content: result.message,
            confirmText: "我知道了",
            type: "alert"
          })
        } else {
          this.$toast(result.message)
        }
        this.order.ordering = false
        loading.hide()
      })
    },
    submit: function() {
      if (!this.checkOrder()) return
      this.checkOffset()
    },
    setPayway: function(type, props) {
      this.order.payment = type
      props.hide()
    },
    goPrepay: function() {
      this.navigator.push({
        name: "dispatchPay",
        query: {
          expid: this.orderDetail.expId,
          dispatchid: this.order.market.dispatchid
        },
        params: {
          source: "dispatch"
        }
      })
    },
    goDetail: function() {
      let jump = this.sntSign ? 'replace' : 'push'
      this.navigator[jump]({
        name: "dispatchDetail",
        query: {
          expid: this.orderDetail.expId,
          dispatchid: this.order.market.dispatchid
        },
        params: {
          source: "dispatch"
        }
      })
    },
    getCoupons: function() {
      if(this.sntSign || !this.islogin || !this.order.market.dispatchid || !this.order.send.xzqName || !this.order.rec.xzqName) return
      this.$http.get("/apicenter/card.do?method=kdbestcoupon", {
        data: {
          dispatchid: this.order.market.dispatchid,
          sendxzq: this.order.send.xzqName,
          recxzq: this.order.rec.xzqName
        }
      }).then(result => {
        this.coupons = result.data || []
      })
    },
    selectCoupon: function(item) {
      if (this.order.send.xzqName && this.order.rec.xzqName) {
        if (this.order.coupon.id == item.id) {
          this.order.coupon = {}
        } else {
          this.order.coupon = item
        }
      }
    },
    catComment: function(text) {
      if (this.order.comment.indexOf(text) == -1) {
        this.order.comment += ' ' + text
      } else {
        this.order.comment = this.order.comment.replace(text, '').replace('  ', ' ')
      }
    },
    getAuth: function(callback) {
      this.$http.get("/apicenter/order.do?method=queryUserCardInfo").then(result => {
        if (result.data) {
          this.auth = true
        } else {
          this.auth = false
        }
        typeof callback == 'function' && callback()
      })
    },
    goAuth: function() {
      this.doAuth = true
      if(this.islogin) {
        this.navigator.push({
          name: "auth"
        })
      } else {
        this.navigator.push({
          name: "login"
        })
      }
    },
    loginCallback: function() {
      this.islogin = true
      this.getCoupons()
      this.getUserInfo()
      /**去除实名认证逻辑 
      if (!this.doAuth) {
        this.getAuth()
        return
      }
      this.doAuth = false
      if (this.login2submit) {
        this.submit()
      } else {
        this.getAuth(() =>{
          if (this.auth) {
            this.$toast("该账户已实名登记", 1500)
            this.authCallback()
          } else {
            this.goAuth()
          }
        })
      }
      */
    },
    getUserInfo: function() {
      this.$http.get("/user/userapi.do?method=getuserinfo", {
        handleFail: false
      }).then(data =>  {
        this.userMobile = data.user.mobile
      })
    },
    authCallback: function() {
      this.auth = true
      if (this.waitSubmit) {
        this.submit()
      }
    },
    sendCode: function(){
      if(this.validator.time) return
      if(!/^1\d{10}$/.test(this.validator.phone)){
        return this.$toast('请输入正确的验证手机号码')
      }
      if(this.validator.valid && !this.validator.img) {
        return this.$toast('请输入图片验证码')
      }
      this.$http.post('/pcweb/sms/smssend', {
        data: {
          name: this.validator.phone,
          validcode: this.validator.img,
          platform: PLATFORM
        }
      }).then(() => {
        this.validator.sended = true
        this.$toast("验证码已经发送到您的手机")
        this.calcTime()
      }).catch(r => {
        if(r.status == '10010') {
          this.validator.valid = true
          this.$toast("请输入图片验证码")
          this.random = Math.random()
        } else if(r.status == '10011'){
          this.validator.valid = true
          this.$toast("图片验证码错误",1000)
        } else {
          this.$toast(r.message)
        }
      })
    },
    phoneLogin: function(){
      if(!this.validator.sended) return
      this.validator.validating = true
      this.$http.post("/sso/appapi.do?method=webclogin", {
        data: {
          phone: this.validator.phone,
          code: this.validator.code
        }
      }).then(rst => {
        let store = this.$store.state.globalData
        this.validator.validating = false
        if(store.coname && store.appid && store.openid || store.isWechat && !store.isMiniProgram) {//第三方接入用户和快递100公众号登录，则记录会话的本地存储
          util.setSession("TOKEN", rst.token)
        } else {//第三方公众号接入且没有接入用户信息的，则记录长期的TOKEN，在个人中心提供退出登录功能供用户自行退出
          util.setcookie('TOKEN', rst.token)
        }
        this.islogin = true
        this.$toast("验证成功", 1500)
        this.login(() => {
          //this.getAuth()
        })
      }).catch(() => {
        this.validator.validating = false
      })
    },
    calcTime: function(){
      this.validator.time = 60
      let calcTimer = setInterval(() => {
        if(!--this.validator.time){
          clearInterval(calcTimer)
          calcTimer = null
        }
      }, 1000)
      this.$once("hook:beforeDestroy", () => {
        clearInterval(calcTimer)
      })
    },
    toWebview: function(name) {
      this.navigator.push({
        name: name,
        params: {
          source: "dispatch"
        }
      })
    },
    locateFail () { // 定位失败
      let lastLocate = util.getStorage("lastLocate")
      if (lastLocate && lastLocate.position) {
        this.setLocateInfo(lastLocate)
      } else {
        this.$toast('没有定位权限，请先定位')
        setTimeout(()=>{
          this.navigator.switch({
            name: "courier"
          })
        }, 1000)
      }
    },
    setLocateInfo (locateInfo) { //格式化地址信息
      this.$store.commit("setLocate", locateInfo)
      this.position = {
        lng: locateInfo.position.lng,
        lat: locateInfo.position.lat
      }
      let gotaddr = locateInfo.addressComponent || {}
      util.setSession("gotaddr", {
        addr: locateInfo.formattedAddress || "",
        xzq: [gotaddr.province || "", gotaddr.city || "", gotaddr.district || ""].join(",")
      })
      this.init()
      /* const gotXzq = {
        pro: this.province || '',
        city: this.city || '',
        district: this.district || '',
      }
      this.gotXzq = gotXzq */
    },
  },
  created() {
    if(!this.locationInfo.lat || !this.locationInfo.lng) {
      util.locate({
        success: (data, locateInfo) => {
          this.position = data
          let gotaddr = locateInfo.addressComponent || {}
          util.setSession("gotaddr", {
            addr: locateInfo.formattedAddress || "",
            xzq: [gotaddr.province || "", gotaddr.city || "", gotaddr.district || ""].join(",")
          })
          this.init()
        }, 
        fail: () => {
          this.locateFail()
        }
      }, this)
    } else {
      this.locateFail()
    }
    /* let position = this.position
    if(!position.lat || !position.lng) {
      util.locate({
        success: (data, locateInfo) => {
          this.position = data
          let gotaddr = locateInfo.addressComponent || {}
          util.setSession("gotaddr", {
            addr: locateInfo.formattedAddress || "",
            xzq: [gotaddr.province || "", gotaddr.city || "", gotaddr.district || ""].join(",")
          })
          this.init()
        }, 
        fail: () => {
          // this.goCourier()
        }
      }, this)
    } else {
      this.init()
    } */
    this.order.gotaddr = util.getSession("gotaddr") || {}
    this.$broadcastChannel.$on('do-agree', () => {
      this.order.agree = true
    })
    this.$broadcastChannel.$on('do-valins-agree', () => {
      this.order.valins.agree = true
    })
    this.$broadcastChannel.$on("removeContact", id => {
      if(this.order.rec.id == id) this.order.rec.id = ""
      if(this.order.send.id == id) this.order.send.id = ""
    })
  },
  computed: {
    ...mapState({
      locationInfo: state => state.position,
    }),
    sendman: function() {
      return this.order.send.name ? this.order.send.name + " " + (this.order.send.phone || this.order.send.fixedPhone || '') : '';
    },
    recman: function() {
      return this.order.rec.name ? this.order.rec.name + " " + (this.order.rec.phone || this.order.rec.fixedPhone || '') : '';
    },
    cargoStr: function() {
      var c = this.order.cargo.name;
      var type = this.order.cargo.type;
      // var valins = this.order.valins.check ? "保价" : "不保价"
      if (type == 'other') c = this.order.otherCargo;
      if (!c) return '';
      this.order.weight && (c += '/' + this.order.weight + 'kg')
      // c += "/" + valins;
      return c;
    },
    isShenzhen: function() {
      return this.order.send.xzqName.indexOf("深圳") != -1;
    },
    selectcom: function() {
      var that = this;
      this.order.comable = 'default'
      return this.order.company ? "" : (function() {
        var str = [], comlist = [], coms = that.order.companys;
        for (var i in coms) {
          if (i != 'length') {
            comlist.push(coms[i].kuaidiCom)
            str.push(coms[i].name)
          }
        }
        that.order.comable = comlist.join(",")
        return str.join("/")
      }())
    },
    selelcttime: function() {
      return (this.days[this.selectDaytime.day] || '') + (this.selectDaytime.time || '')
    },
    couponText: function() {
      var order = this.order
      return order.coupon.id ?
        order.coupon.top_limit == order.maxCoupon ? '已选最大优惠 -' + order.coupon.sub_title + '元' : '-' + order.coupon.sub_title + '元' :
        this.coupons.length ? '有' + this.coupons.length + '张优惠券' : '没有可用优惠券'
    },
    doortimeTips: function() {
      return this.selectDaytime.timeKey == '2' ? this.times.tips : '请至少提前半小时预约上门时间，若是紧急件请与快递员沟通，实际上门时间以协商后为准'
    },
    leftPaytime: function() {
      var time = this.orderDetail.premanenttime;
      if (time) {
        return ('0' + ~~(time / 60)).slice(-2) + '分' + ('0' + (time % 60)).slice(-2) + '秒'
      }
    },
    showBrand: function() {
      return this.order.send.xzqName && this.order.rec.xzqName && this.cargoStr;
    },
    finalPrice: function() {
      var priceinfo = this.priceinfo;
      var order = this.order;
      if(order.market.com === "kuaidi100") {
        return +priceinfo.costTotalPrice ? +(+priceinfo.totalprice + order.valins.fee - (order.coupon.top_limit || 0)).toFixed(2) + '元' : "--元"
      } else {
        return +priceinfo.costTotalPrice ? +(+priceinfo.totalprice + order.valins.fee).toFixed(2) + '元' : "--元"
      }
      
    },
    showValinsForm: function() {
      let order = this.order
      return !(order.rec.xzqName && order.send.xzqName && this.cargoStr)
    },
    submitOrderSource: function() {
      let orderSource = this.orderSource || this.$store.state.globalData.coname || this.$store.state.globalData.from  || ""
      return "h5_dispatch" + (this.sendAgain ? "_sendAgain" : "") + (orderSource ? "_" + orderSource : "")
    }
  },
  watch: {
    'order.send.phone': function(val) {
      this.ignoreMobileCheck = false
      this.validator.phone = val
    },
    'order.send.xzqName': function() {
      this.getExpressBrand()
    },
    'order.rec.xzqName': function() {
      this.getExpressBrand()
    },
    'order.valins.value': function(val) {
      var market = this.order.market
      if(val === '') return 0
      if(val > market.valinsmax) {
        this.$toast('仅支持' + market.valinsmax + '元以下的物品保价')
        this.order.valins.value = (val + '').slice(0, -1)
      }
      if(val % 100 == 0) {
        this.order.valins.fee = Math.ceil(Math.max(market.valinsmin, (val * market.valinsrate))) 
      } else {
        this.order.valins.fee = 0
      }
    },
    "contactStatus.detail.xzq": function(val) {
      var city = val.split(" ")[1]
      this.autocomplete.setCity(city);
    },
    'order.comable': function() {
      this.getDoortime()
    },
    'selectDaytime.day': function(val) {
      val && this.selectDoortime(this.times[val][0])
    },
    'order.company': function(val) {
      if (val == 'default') {
        this.order.companys = []
      }
    },
    'order.comment': function(val, old) {
      if (this.order.comment.length > 30) this.order.comment = old
    },
    'order.agree': function(val) {
      util.setStorage("dispatchAgree", +val)
    },
    'order.weight': function(val, old) {
      if (val === "") return;
      if (val < 1) {
        this.order.weight = old;
      } else if (val > 20) {
        this.order.weight = old;
        this.$toast("物品重量不能超过20kg", 1000)
      } else {
        this.order.weight = parseInt(val) || ''
      }
    },
    'order.otherCargo': function(val) {
      this.order.otherCargo = val.slice(0, 8)
    },
    'order.cargo': function() {
      this.order.cargoInclude = []
    },
    'validator.code'(val) {
      let v = this.validator.code = val.slice(0, 6)
      if(v.length === 6) {
        this.phoneLogin()
      }
    }
  },
  beforeDestroy: function() {
    this.tprepay && clearInterval(this.tprepay)
  },
  beforeRouteEnter(to, from, next) {
    if(to.query.kdNum && !from.name) {
      return kuaidi100APP.navigator.push({
        name: "sendback",
        query: {
          kdNum: to.query.kdNum,
          backExpId: to.query.backExpId
        }
      })
    }
    next(vm => {
      if(from.name == 'login' && vm.$store.state.globalData.token) {
        vm.loginCallback()
      }
      // 去除实名认证逻辑
      // if(from.name == 'auth' && vm.$store.state.userAuth ) {
      //   vm.authCallback()
      // }
    })
  }
}